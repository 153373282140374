<div class="flex modal-header">
    <h2>Describe Adjustment</h2>
</div>
<div class="modal-body">
    <div class="hrow"><b>{{getRoundedTauValue(skuData.tauValueFromStep2)}} Original Tau </b> - {{lastUpdated}} </div>
    <div class="hrow override history-save-container">
        <div *ngFor="let log of skuData.auditTrailLogs;index as i"
             [ngClass]="hasAdjustment || log.reasonForAdjustment?'has-desc-modal':'edit-result'" class="history">
            <div class="history-justify">
                <div><b>{{i + 1}}
                )</b> {{getRoundedTauValue(log.value)}} {{log.userName}} - {{log.lastUpdatedDate}}</div>
                <div class="button-section" (click)="onClickEdit(log)"  [ngClass]="activeAuditLog && activeAuditLog.id===log.id ? 'hide-icon':'show-icon'">
                    <span class="sif sif-edit edit-btn"></span>
                </div>
            </div>
            <div class="reason-adj" *ngIf="!activeAuditLog || log.id!==activeAuditLog.id">{{log.reasonForAdjustment}}</div>
            <textarea (keyup)="onEditValue($event)" *ngIf="(activeAuditLog && activeAuditLog.id===log.id)"
                      class="description-text-area" rows="3" [(ngModel)]="activeAuditLog.reasonForAdjustment"
                      placeholder="e.g. Volume too high compared to in-market velocitites. Taking down to better fit within the category.">{{activeAuditLog.reasonForAdjustment}}</textarea>
        </div>
    </div>
</div>

<div mat-dialog-actions class="buttons">
    <button mat-button mat-dialog-close class="mat-tertiary" (click)="onClickRevert()"><span
            class="sif sif-undo"></span>&nbsp;<span>Reset</span></button>
    <div>
        <button mat-button mat-dialog-close class="mat-tertiary">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!enableApply" (click)="onClickApply()">Apply</button>
    </div>
</div>
