<app-message-prompt *ngIf="importScenarioFailureMessage" [message]="importScenarioFailureMessage" [type]="'danger'" (promptCloseEvent)="closeImportScenarioErrorPrompt()" class="import-scenario-failure"></app-message-prompt>
<div class="container-fluid mr-t-2">
    <div class="flex margin-bottom-2" *ngIf=!scenarioCompareView>
        <app-scenario-group-filter
            (scenarioImportEvent)="importScenario($event)"
            (scenarioExportEvent)="exportScenario()"
            (dropDownChanged)="changeScenario($event)"
            (filter)="search($event)"
            [groupItemStatus]="groupItemStatus"
            [enableActionMenu]=true
            [enableImportActionMenu]="modelRun.runId"
            [bulkEditStatus]="bulkEditStatus"
            [selectedScenarioIds]="selectedScenarioId"
            [referenceScenarioData]="referenceScenarioData"
            [scenarioGroupsData]="scenarioGroupsData"></app-scenario-group-filter>
        <button mat-icon-button id="compareScenariosId" [disabled]="disableCompareScenario" [ngClass]="[disableCompareScenario ? 'disabled': '']" [title]="compareScenarioTooltip" class="create-scenario-btn ml-3 compare-btn" (click)=toggleScenarioCompareView()><span class="sif sif-comparison"></span></button>
        <button mat-icon-button id="add-scenario-btn" matTooltip="Add Scenario" [matTooltipPosition]="'above'" class="create-scenario-btn ml-3" [disabled]="groupItemStatus || bulkEditStatus || !isSelectedScenarioAvailable" color="secondary" (click)="openCreateScenarioDialog()" aria-label="Create Scenario"><span class="sif sif-add"></span></button>
        <div class="btn-group-right">
            <button mat-icon-button id="advanced-settings" matTooltip="Advanced Settings" *ngIf=enableAdvanceSettings (click)="onAdvanceSettingsFlyoutToggle(true)"><span class="sif sif-gear"></span></button>
            <button mat-icon-button id="calibrate" *ngIf=showCalibrateButton [ngClass]="[disableCalibration ? 'disabled': '']" [title]="calibrationTooltip" (click)="onCalibrationClick()"><span class="sif sif-dataset-1"></span></button>
            <button mat-icon-button id="create-report" *ngIf="isInternalUser" [ngClass]="[disableReport ? 'disabled': '']" [title]="reportTooltip" (click)="onCreateReportClick()"><span class="sif sif-report"></span></button>
            <button mat-icon-button id="bulk-edit-btn" aria-label="Close" [ngClass]="[(disableLockScenario || !scenarioLockedForEdit || disableBulkEdit) ? 'disabled': '']" (click)="onBulkEditClick()" [disabled]="disableLockScenario || !scenarioLockedForEdit || disableBulkEdit || !isSelectedScenarioAvailable"><span class="sif sif-bulk-edit"></span></button>
            <button mat-icon-button aria-label="Close" [disabled]="!isSelectedScenarioAvailable" matTooltip="Item Characteristics" (click)="onGroupItemClick()"><span class="sif sif-group"></span></button>
            <button mat-icon-button aria-lable="close" matTooltip="Reorder Items" (click)="openSkuConfigsItemReorderPage()"><span class="sif sif-reorder"></span></button>
            <span class="separator-vert"></span>
            <mat-slide-toggle
                [id]="'scenario-lock-toggle'"
                [checked]="scenarioLockedForEdit"
                [disabled]="disableLockScenario || !isSelectedScenarioAvailable"
                [title]="scenarioUsedInCalibrationTooltip ? scenarioUsedInCalibrationTooltip : scenarioLockedForEdit ? 'Edit Mode':'Read-only Mode'"
                (toggleChange)="toggleScenarioLock()">
            </mat-slide-toggle>
            <span class="separator-vert"></span>
            <button class="simulate-save-btn" mat-flat-button color="primary" [disabled]="disableSimulateAndSave" (click)="simulateAndSave()">{{simulateAndSaveButtonLabel}}</button>
        </div>
    </div>
    <mat-card *ngIf=!scenarioCompareView>
        <ng-template [ngIf]="isSelectedScenarioAvailable" [ngIfElse]="selectedScenarioNotAvailable">
        <app-message-prompt *ngIf="lockMessage" [message]="lockMessage" [type]="'warn'" ></app-message-prompt>
        <div *ngIf=!reorderItemsPage>
            <div *ngIf=modelRun.runId class="reset-icon-position">
                <div class="filter-menu-list">
                    <app-population-filter
                        *ngIf="showPopulationFilter"
                        [disable]="groupItemStatus || bulkEditStatus || slideOpenAdvanceSettings"
                        [filters]="filters"
                        [nSize]="nSize"
                        [nSizeClass]="lowPopulationMsgType"
                        (selectionChange)="onFilterSelectionChange($event)"></app-population-filter>
                </div>
                <button *ngIf=!hidePopulationFiltersReset mat-icon-button matTooltip="Reset Filters"
                        [disabled]="!resetPopulationFiltersStatus"
                        (click)="resetPopulationFilters()">
                    <span class="sif sif-reset"></span>
                </button>
            </div>
            <app-message-prompt *ngIf="lowPopulation" [message]="lowPopulationMsg" [type]="lowPopulationMsgType" class="population-warning"></app-message-prompt>
            <app-message-prompt *ngIf="outputsFilteredOnNonSampleFilterToggleTooltip" [message]="outputsFilteredOnNonSampleFilterToggleTooltip" [type]="'warn'"  class="non-sample-filter-engage-warning"></app-message-prompt>
            <div class="scenarios-container">
                <router-outlet></router-outlet>
                <app-bulk-edit class="flyout-menu" (clicked)="onBulkEditCloseClick($event)" [metaData]="metaData"
                    [ngClass]="bulkEditStatus ? 'flyout-menu-open' : 'flyout-menu-close'" [selectedScenarioId]="selectedScenarioId">
                </app-bulk-edit>
                <app-group-items
                    class="flyout-menu"
                    (clicked)="onGroupItemCloseClick($event)"
                    (skuGroupReload)="skuGroupReload()"
                    [skuGroups]="skuGroups"
                    [ngClass]="groupItemStatus ? 'flyout-menu-open' : 'flyout-menu-close'"></app-group-items>
                <app-advanced-settings
                    *ngIf=enableAdvanceSettings
                    class="flyout-menu"
                    [generalSetting]="generalSetting"
                    [ngClass]="slideOpenAdvanceSettings ? 'flyout-menu-open' : 'flyout-menu-close'"
                    [adminView]="enableAdvanceSettingsAdminView"
                    (closeAdvancedSettings)="onAdvanceSettingsFlyoutToggle($event)"
                    (saveAdvancedSettings)="onAdvanceSettingsSave($event)">
                </app-advanced-settings>
            </div>
        </div>
        </ng-template>
        <ng-template #selectedScenarioNotAvailable>
            <app-empty-state
                [size]="'medium'"
                [icon]="'sif sif-data-grid-detailed'"
                [iconColor]="'primary'"
                [title]="'Scenario not Found'"
                [message]="' Scenario has been deleted or archived.'"
                [subMessage]="'Please select a new scenario to display.'">
            </app-empty-state>
        </ng-template>
    </mat-card>
    <div *ngIf=scenarioCompareView >
        <app-compare-scenarios
            (clicked)="toggleScenarioCompareView()"
            [modelRunSkus]="modelRunSkus"
            [metaData]="metaData"
            [skuGroups]="visibleSkuGroups"
            [generalSetting]="generalSetting"
            [scenarios]="scenarios"
            [scenarioGroups]="scenarioGroups"
            [selectedScenarioId]="selectedScenarioId"
            [filters]="compareViewFilters"
            [nSize]="nSize"
            [lowPopulation]="lowPopulation"
            [lowPopulationMsg]="lowPopulationMsg"
            [lowPopulationMsgType]="lowPopulationMsgType"></app-compare-scenarios>
    </div>
</div>

