import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
import {HotTableRegisterer} from '@handsontable/angular';
import {Subscription} from 'rxjs';
import {ScenarioService} from '../../services/scenario.service';
import {ModelRunService} from '../../services/model-run.service';
import {UiBlockerService} from '../../services/ui-blocker.service';
import {EnvironmentService} from '../../services/environment.service';
import {SkuGroupService} from '../../services/sku-group.service';
import {AppConstantsService} from '../../services/app-constants.service';
import {ModelRunSku} from '../../models/model-run-sku.model';
import {ModelRunSkuService} from '../../services/model-run-sku.service';
import {MetaData} from '../../models/meta-data.model';
import {SkuConfigTableCellRenderers} from '../../utils/sku-config-table-cell-renderers';
import {SkuConfigTableColumn} from '../../utils/sku-config-table-column';
import {UserConfigurations} from '../../models/user-configurations.model';
import {SkuGroup} from '../../models/sku-group.model';
import {ItemCharacteristicsSku} from '../../interfaces/item-characteristics.interface';
import {ItemGrouping} from '../../models/item-grouping.model';

@Component({
    selector: 'app-inputs-config-table',
    templateUrl: './inputs-config-table.component.html',
    styleUrls: ['./inputs-config-table.component.scss'],
})
export class InputsConfigTableComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() skuGroups: Array<SkuGroup>;
    @Input() modelRunSkus: Array<ModelRunSku>;
    @Input() metaData: MetaData;
    @Input() cellRenderers: SkuConfigTableCellRenderers;
    @Input() skuOrderConfig: UserConfigurations;
    @Input() activeItemGrouping: ItemGrouping;
    @Input() activeSkuGroup: SkuGroup;
    @Output() updateSkusItemGrouping = new EventEmitter<{ closeFlyout: boolean; skus: Array<ItemCharacteristicsSku> }>();

    subscriptions: Subscription;
    hotTableInstance: any;
    hotTableId = 'skuConfigTable';
    isAllSkusSelectedState: number;
    selectedSkuCount: number;
    hotTableData: any = [];
    tableSettings = {
        columns: [],
        columnHeaders: [],
        groupHeaders: [],
        allowFiltersForHeaders: [2],
        groupColumnStartIndex: 3,
        inputsColumnStartIndex: 0,
        outPutColumnStartIndex: 0
    };

    constructor(private hotTableRegisterer: HotTableRegisterer,
                private scenarioService: ScenarioService,
                private modelRunService: ModelRunService,
                private uiBlockerService: UiBlockerService,
                private environmentService: EnvironmentService,
                private skuGroupService: SkuGroupService,
                private appConstantsService: AppConstantsService,
                private modelRunSkuService: ModelRunSkuService) {
    }

    ngOnInit(): void {
        this.subscriptions = new Subscription();
        this.constructTableSettings();
        this.constructTableData();
        this.subscriptions.add(this.scenarioService.onClickSaveChangesSubject.subscribe((data) => {
            const skus = this.changedHotTableData();
            this.updateSkusItemGrouping.emit({closeFlyout: data.closeFlyout, skus});
        }));
    }

    constructTableSettings(): void {
        const skuGroup = this.activeSkuGroup;
        const tableSettings = this.tableSettings;
        tableSettings.groupHeaders = [];
        tableSettings.columnHeaders = [];
        tableSettings.columns = [];
        const skuConfigTableColumn = new SkuConfigTableColumn(this.cellRenderers);
        [
            {
                label: '',
                children: [skuConfigTableColumn.skuIdColumn(), skuConfigTableColumn.selectSkuColumn(skuGroup ? false : true), skuConfigTableColumn.skuNameColumn()]
            },
            {
                label: 'ITEM CHARACTERISTICS',
                children: [skuConfigTableColumn.itemCharacteristicsGroupingColumn(skuGroup ? skuGroup.displayName : '--')]
            }
        ].forEach((header) => {
            tableSettings.groupHeaders.push({label: header.label, colspan: header.children.length});//push parent level header
            header.children.forEach((childHeader) => {
                tableSettings.columnHeaders.push(childHeader.displayName);
                tableSettings.columns.push(childHeader);
            });
        });
    }

    constructTableData(): void {
        const skuGroup = this.activeSkuGroup;
        const modelRunSkus = this.modelRunSkuService.getSkuConfigsInDisplayOrder(this.modelRunSkus, this.skuOrderConfig)
            .map((it) => {
                return this.createItemCharacteristicsSku(it, skuGroup);
            });
        this.hotTableData = modelRunSkus;
    }

    createItemCharacteristicsSku(modelRunSku: ModelRunSku, skuGroup: SkuGroup = null): ItemCharacteristicsSku {
        let itemGroupingText = '--';
        let isSelected = 0;
        let itemGroupingId = null;
        let skuGroupId = null;
        if (skuGroup) {
            const matchedSkuGroup = modelRunSku.groups.find(it => it.skuGroupId === skuGroup.skuGroupId);
            const matchedItemGrouping = matchedSkuGroup ? skuGroup.itemGroupings.find(it => it.itemGroupingId === matchedSkuGroup.itemGroupingId) : null;
            skuGroupId = matchedSkuGroup.skuGroupId;
            itemGroupingId = matchedItemGrouping ? matchedItemGrouping.itemGroupingId : null;
            itemGroupingText = matchedItemGrouping ? matchedItemGrouping.displayName : this.appConstantsService.ALL_OTHER_GROUPING;
            isSelected = itemGroupingText === this.appConstantsService.ALL_OTHER_GROUPING ? 0 : 1;
        }
        //NOTE: MUST HAVE ID IN DATA OR ELSE CHECKBOX DOESN'T RENDER
        return {
            id: modelRunSku.skuId,
            skuId: modelRunSku.skuId,
            isSelected: isSelected,
            reportingName: modelRunSku.reportingName,
            skuGroupId: skuGroupId,
            itemGroupingId: itemGroupingId,
            itemGroupingText: itemGroupingText
        };
    }

    setSelectAllCheckBoxState(): void {
        const skuConfigRows = this.hotTableData.filter(it => it.skuId > 0);
        const selectedSkuConfigs = skuConfigRows.filter(it => it.isSelected);
        const totalSkuCount = skuConfigRows.length;
        const selectedSkuCount = selectedSkuConfigs ? selectedSkuConfigs.length : 0;
        const unSelectedSkuCount = totalSkuCount - selectedSkuCount;

        this.selectedSkuCount = selectedSkuCount;
        if (selectedSkuCount === totalSkuCount) {
            this.isAllSkusSelectedState = this.appConstantsService.ALL_SKUS_SELECTED;
        } else if (unSelectedSkuCount === totalSkuCount) {
            this.isAllSkusSelectedState = this.appConstantsService.NO_SKUS_SELECTED;
        } else {
            this.isAllSkusSelectedState = this.appConstantsService.PARTIAL_SKUS_SELECTED;
        }
    }

    onSkuGroupOrItemGroupingSelectionChange(data): void {
        this.constructTableData();
        this.constructTableSettings();
        this.loadHotTable();
    }

    ngAfterViewInit(): void {
        this.loadHotTable();
    }

    ngOnChanges(): void {
        this.constructTableData();
        this.constructTableSettings();
        this.loadHotTable();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    loadHotTable(): void {
        this.hotTableInstance = this.hotTableRegisterer.getInstance(this.hotTableId);
        if (this.hotTableInstance) {
            this.generateTable(this.hotTableData, this.hotTableInstance);
            this.setSelectAllCheckBoxState();
            this.hotTableInstance.render();
        }
    }

    get handsontableLicenseKey(): string {
        const handsontableConfig = this.environmentService.environment.handsontable;
        const handsontableLicenseKey = handsontableConfig && handsontableConfig.licenseKey ? handsontableConfig.licenseKey : 'non-commercial-and-evaluation';
        return handsontableLicenseKey;
    }

    getSelectAllCheckBoxHtml(): string {
        let htmlText = `<input type='checkbox'`;
        htmlText += `class='checker ${this.isAllSkusSelectedState === 1 ? 'checked' : (this.isAllSkusSelectedState === 0 ? 'unchecked' : 'partial')}'>`;
        htmlText += `<span class='count-txt'>${this.selectedSkuCount}</span>`;
        return htmlText;
    }

    changedHotTableData(): Array<ItemCharacteristicsSku> {
        const modelRunSkus = this.modelRunSkus;
        return this.hotTableData.filter((it) => {
            const modelRunSku = modelRunSkus.find(sku => sku.skuId === it.skuId);
            return !modelRunSku.groups.find(group => group.skuGroupId === it.skuGroupId && group.itemGroupingId === it.itemGroupingId);
        });
    }

    generateTable(tableData, hotTableInstance): void {
        const self = this;
        const handsontableLicenseKey = this.handsontableLicenseKey;
        hotTableInstance.updateSettings({
            viewportColumnRenderingOffset: 27,
            viewportRowRenderingOffset: 'auto',
            allowInsertColumn: false,
            allowInsertRow: false,
            allowRemoveColumn: false,
            allowRemoveRow: false,
            autoWrapRow: false,
            autoWrapCol: false,
            manualRowResize: true,
            manualRowMove: true,
            manualColumnMove: true,
            colHeaders: self.tableSettings.columnHeaders,
            columns: self.tableSettings.columns,
            height: document.documentElement.clientHeight - 300,
            width: '60%',
            rowHeights: 34,
            manualColumnResize: false,
            data: tableData,
            licenseKey: handsontableLicenseKey,
            nestedHeaders: [
                self.tableSettings.groupHeaders,
                self.tableSettings.columnHeaders
            ],
            renderAllRows: true,
            fixedRowsBottom: 0,
            afterOnCellMouseDown: (event, coords, TD): void => {
                if (event.target.classList.contains('checker')) {
                    event.preventDefault(); // prevent selection quirk
                    event.stopImmediatePropagation();
                    self.hotTableInstance.deselectCell();
                    return;
                }
            },
            /**
             * Make selectAll column header as checkbox.
             * */
            afterGetColHeader(index: number, th: HTMLTableHeaderCellElement) {
                if (this.getColHeader(index) === 'selectAll') {
                    th.innerHTML = self.getSelectAllCheckBoxHtml();
                }
            },
            beforePaste: function (data, coords) {
                for (const coord of coords) {
                    for (let i = coord.startRow, j = 0; i <= coord.startRow + data.length - 1; i += 1, j += 1) {
                        for (let k = coord.startCol, l = 0; k <= coord.startCol + data[j].length - 1; k += 1, l += 1) {
                            const cellMeta = this.getCellMeta(i, k);
                            const type = cellMeta.type;
                            let parsedData;
                            if (type === 'checkbox') {
                                parsedData = data[j][l] === '1' ? 1 : 0;
                            } else {
                                parsedData = data[j][l];
                            }
                            if (!cellMeta.readOnly) {
                                this.setDataAtCell(i, k, parsedData);
                            }
                        }
                    }
                }
                return false;
            },
            afterChange: function (changes, source) {
                let isSelectedToggled = false;
                if (!changes) {
                    return;
                } else {
                    const allOtherGrouping = self.activeSkuGroup.itemGroupings.find(it => it.name == self.appConstantsService.ALL_OTHER_GROUPING);
                    changes.forEach(([row, prop, oldValue, newValue]) => {
                        if (prop === 'isSelected') {
                            const sourceData = this.getSourceDataAtRow(row);
                            const hotTableData = self.hotTableData.find(it => it.skuId === sourceData.skuId);
                            hotTableData.itemGroupingId = newValue === 1 ? self.activeItemGrouping.itemGroupingId : allOtherGrouping.itemGroupingId;
                            isSelectedToggled = true;
                        }
                    });
                }
                if (isSelectedToggled) {
                    self.setSelectAllCheckBoxState();
                    this.render();
                }
                self.scenarioService.onSkuSelectionChangesSubject.next(self.changedHotTableData().length > 0);
            }
        });

        /**
         * Make checkbox editable for skus where the grouping text matches, for others keep them as readonly.
         * */
        hotTableInstance.updateSettings({
            cells(row: number) {
                const cellProperties = {readOnly: true};
                const cellMeta = this;
                if (cellMeta && cellMeta.prop === 'isSelected') {
                    const itemGroupingText = this.instance.getDataAtRowProp(row, 'itemGroupingText');
                    if (itemGroupingText === self.appConstantsService.ALL_OTHER_GROUPING) {
                        cellProperties.readOnly = false;
                    } else {
                        cellProperties.readOnly = !self.activeItemGrouping || (self.activeItemGrouping && (itemGroupingText !== self.activeItemGrouping.displayName));
                    }
                }
                return cellProperties;
            }
        });
    }
}
