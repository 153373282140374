import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {UserManagementService} from '../../services/user-management.service';

@Component({
    selector: 'app-tau-audit-trail-view-dialog',
    templateUrl: './tau-audit-trail-view-dialog.component.html',
    styleUrls: ['./tau-audit-trail-view-dialog.component.scss']
})
export class TauAuditTrailViewDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<TauAuditTrailViewDialogComponent>,
        public userManagementService: UserManagementService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    skuData: any;
    userName: string;


    ngOnInit(): void {
        this.skuData = this.data;
        this.userManagementService.getUser(this.skuData.step2StartedBy).subscribe((user) => {
            this.userName = user.fullName;
        });
    }

    get userFullName(): string {
        return this.userName;
    }

    get lastUpdated(): string {
        return `${moment(this.skuData.step2Ended).fromNow()}`;
    }

    dialogOut(): void{
        this.dialogRef.close(true);
    }

    get hasAdjustment(){
        return this.skuData.hasTauAdjustment;
    }

    getRoundedTauValue(value : number){
        return this.roundTo(value,5);
    }

    roundTo(n, digits): any {
        const multiplicand = Math.pow(10, digits);
        n = parseFloat((n * multiplicand).toFixed(12));
        n = (Math.round(n) / multiplicand).toFixed(digits);
        return n;
    }

}
